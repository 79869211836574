import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OrderPropisEng from "../components/orderPropisEng"

import ImagePropis from "../images/english-cover.jpg"
import Slider from "react-slick"

import russian1 from "../images/eng-1.jpg"
import russian2 from "../images/eng-2.jpg"
import russian3 from "../images/eng-3.jpg"
import russian4 from "../images/eng-4.jpg"

const imgEng = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
  {
    id: 3,
    pic: russian3,
  },
  {
    id: 4,
    pic: russian4,
  },
]

const PropisEng = () => {
  const imageMap = imgEng.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи английский язык" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} />
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи английский язык
          </h1>
          <p className="mt-12">
            Прописи по английскому языку предназначены для младших школьников.
          </p>
          <p>
            Упражнения в учебном пособии разбиты на несколько блоков:
            <br />
            - маленькие и большие прописные буквы;
            <br />
            - слова с большой и маленькой буквы;
            <br />
            - цифры.
            <br />
          </p>
          <p>
            Сборник упражнений поможет вам освоить английскую письменную речь.
            Прописывая слова, вы улучшите навыки письма и запомните правильное
            написание слов.
          </p>
        </div>
      </div>
      <OrderPropisEng />
      <div className="mt-24 mb-24">
        <Slider {...settings}>{imageMap}</Slider>
      </div>
    </Layout>
  )
}

export default PropisEng
